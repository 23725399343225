.entity-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .entity-type-icon {
        fill: var(--ecr-client-primary-color, black);
        position: absolute;

        ellipse,
        path {
            fill: var(--ecr-client-primary-color, black);
        }
    }

    &.in-dialogue {
        position: absolute;
        top: -23.5px;
        left: 60.5px;
    }

    &.in-button {
        position: relative;
        top: -3px;
        left: 4px;
    }

    &.in-teaser-slide {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
