.ecr-entities-slide {
    width: 211px;
    height: 367px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f4f4f4;
    border-radius: 12px;

    .question-block {
        padding: 15px 10px 0 16px;
        text-align: center;
        font-family: var(--ecr-client-font-family, Arial);
        font-size: 16px;
        font-weight: 700;
        height: 137px;
        box-sizing: content-box;

        .client-color-dialogue-icon {
            z-index: 100;
            position: relative;
            top: -2px;
            left: 6px;
            fill: var(--ecr-client-primary-color, black);

            text {
                fill: white;
            }
        }

        .dialogue-black-icon {
            z-index: 50;
            position: relative;
            top: 8px;
            left: -13px;
        }

        .dialogue-white-icon {
            z-index: 25;
            position: relative;
            left: -11px;
            top: -14px;

            text {
                fill: #333333;
                font-size: 18px;
            }
        }
    }

    .read-more-headline {
        color: #333333;
        font-family: Helvetica, Arial, sans-serif;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        margin: 5px 0 12px;
        flex-grow: 1;
    }

    .entities-buttons {
        display: flex;
        flex-direction: column;
        padding: 0 10px 22px;
        height: auto;
        min-height: 170px;
        justify-content: space-evenly;

        .entity-btn {
            all: unset;
            font-family: Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-size: 15px;
            text-transform: uppercase;
            color: #333333;
            background: white;
            border-radius: 0 0 0 10px;
            display: flex;
            align-items: center;

            &:hover {
                box-shadow:
                    inset 2px 2px 3px rgba(255, 255, 255, 0.2),
                    inset -2px -2px 3px rgba(0, 0, 0, 0.2);
            }

            &:active {
                box-shadow:
                    inset -2px -2px 3px rgba(255, 255, 255, 0.2),
                    inset 2px 2px 3px rgba(0, 0, 0, 0.2);
            }
        }

        .entity-name {
            margin-left: 14px;
            max-height: 37px;
            overflow: hidden;
            word-break: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
        }

        > *:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    //slide's second state
    @keyframes moveLoadIcon {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes fade-out {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes scaleAndMove {
        0% {
            transform: scale(1);
            left: 0;
            opacity: 0;
        }
        50% {
            transform: scale(1.1);
            left: 20px;
        }
        100% {
            transform: scale(1);
            left: 0;
            opacity: 1;
        }
    }

    .loading-dialogue-container {
        position: relative;

        .dialogue-big-icon {
            fill: var(--ecr-client-primary-color, black);
        }
    }

    .dialogue-content-container {
        position: absolute;
        top: 36.5px;
        text-align: center;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 190px;

        .entity-name-headline {
            font-family: Helvetica, Arial, sans-serif;
            font-weight: 700;
            font-size: 15px;
            margin: 0;
            padding: 0 4px;
            word-break: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }

        .loading-headline {
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            margin: 0;

            &.loaded {
                padding: 0 8px;
                animation: fade-in forwards 0.4s;
            }

            &.loading {
                width: 55%;
                animation: fade-out forwards 0.3s;
                animation-delay: 1.7s;
            }

            &.error {
                padding: 0 8px;
                height: 70%;
                animation: fade-in forwards 0.4s;
            }
        }

        .loading-icons-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &.loading {
                animation: fade-out forwards 0.3s;
                animation-delay: 1.7s;
            }

            &.loaded {
                animation: fade-in forwards 0.4s;
                /*animation: scaleAndMove forwards 0.4s;*/
                position: relative;
            }

            .done-icon {
                position: absolute;
                width: 30px;
                opacity: 0;
                animation: fade-in 0.5s forwards;
                animation-delay: 1.5s;
            }

            .load-icon {
                animation: moveLoadIcon 1s forwards;
                animation-iteration-count: infinite;
            }
        }
    }
}

@media all and (min-width: 415px) and (max-width: 550px) {
    .ecr-entities-slide {
        width: 230px;
        height: 394px;

        .question-block {
            padding: 20px 15px 0 30px;
            overflow: hidden;
        }
    }
}

@media all and (min-width: 551px) {
    .ecr-entities-slide {
        width: 230px;
        height: 400px;

        .question-block {
            padding: 20px 15px 0 30px;
            overflow: hidden;
        }
    }
}
